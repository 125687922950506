import * as React from "react"
import { useEffect } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Logo from "../images/logo.svg"
import defaultSiteLogoUrl from "../images/logo.svg"
import Moon from "../images/moon.svg"
import SearchBox from './SearchBox'
import Wishlist from "../images/wishlist.svg"
import Poland from "../images/poland.png"
import England from "../images/eu-flag.png"
import EULogo from "../images/logo_UE-EN.svg";
import NewsletterForm from './newsletter'

// bootstrap
import 'bulma/css/bulma.min.css';


export default function Header() {

  const {
    allWpCategory,
    allWpPost,
    allWpProductCategory,
    wp: {
      header: { siteTitle, siteTagLine, siteLogoUrl },
    }
  } = useStaticQuery(graphql`
    query navQuery {
    allWpCategory(filter: {language: {code: {eq: EN}}}) {
      nodes {
        name
        uri
      }
    }
    allWpPost(filter: {language: {code: {eq: EN}}}) {
      nodes {
        title
        uri
        id
      }
    }
    allWpProductCategory(filter: {language: {code: {eq: EN}}}) {
      nodes {
        name
        uri
      }
    }
    wp {
      header: getHeader {
        siteLogoUrl
        siteTagLine
        siteTitle
        favicon
      }
    }
  }

  `)

  const siteLogoURL = siteLogoUrl ? defaultSiteLogoUrl : defaultSiteLogoUrl;


  useEffect(() => {
  
    toogleNav()
    
  })
    
    return (
      <>
      <div style={{width:'100%',fontSize:'12px',height:'45px',lineHeight:'45px',backgroundColor:'#F7ADB6',color:'#111111',textAlign:'center'}}>
      <img
        className="eu__logo"
        src={EULogo}
        style={{position:'absolute',height:'45px',left:'0px',top:'0px'}}
        width="auto"
        height="45px"
        alt="European Union"
      />
      <a id="promo-bar" style={{color:'#111111',textDecoration:'none'}} href="https://shop.shroom4you.com" target="_blank" rel="noopener noreferrer"><span role="img" aria-label="Free shipping! ">✨</span>&nbsp;<b>Free shipping</b> on orders over 200PLN&nbsp;<span role="img" aria-label=" Free shipping!">✨</span></a>
      </div>
      <header className="site-header-container">
        <div className="site-header" style={{background:'#FCCDB7'}}>
          <div className="site-brand">
            <Link to="/">
  	          <figure style={{marginBottom:'-20px'}}>
  		          <img
  			          className="site-brand__logo"
  			          src={siteLogoURL}
  			          width="128"
  			          height="auto"
  			          alt={siteTitle}
  		          />
  	          </figure>
            </Link>
            <br />
          </div>
          <div style={{display:'flex',alignItems:'center'}}>
          <nav className="header-nav">
          <span className="navbar-burger" data-target="navbarMenuHeroB">
            <span></span>
            <span></span>
            <span></span>
          </span>
          <div className="container" id="mob-m">
            <div id="navbarMenuHeroB" className="navbar-menu">
              <div className="navbar-end">

              <div className="navbar-item head-item has-dropdown is-hoverable" style={{marginLeft:'20px',marginRight:'20px'}}>
                <a target="_blank" rel="noreferrer" href="https://shop.shroom4you.com" role="link" id="dropdownMenuLink2" data-bs-toggle="dropdown" aria-expanded="false">
                  Shop
                </a>
                <ul className="navbar-dropdown" aria-labelledby="dropdownMenuLink2">
                {allWpProductCategory.nodes.map(post => {
                  return (
                  <>
                  <li><a target="_blank" rel="noreferrer" className="navbar-item head-item" href={'https://shop.shroom4you.com'+post.uri}>{post.name}</a></li>
                  </>)})}
                </ul>

              </div>



            {/*<SearchBox searchIndex={allWpPost} />*/}
              <div className="navbar-item head-item is-danger has-dropdown is-hoverable" style={{marginLeft:'20px',marginRight:'20px'}}>
                <Link to="/blog/" role="link" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                  Blog
                </Link>
                <ul className="navbar-dropdown" aria-labelledby="dropdownMenuLink">
                {allWpCategory.nodes.map(post => {
                  return (
                  <>
                  <li><Link className="navbar-item head-item" to={post.uri}>{post.name}</Link></li>
                  </>)})}
                </ul>
              </div>

              <span className="navbar-item head-item">
                <a target="_blank" rel="noreferrer" href="https://shop.shroom4you.com/our-story/">
                  About shroom
                </a>
              </span>

              <span className="navbar-item head-item">
                <a target="_blank" rel="noreferrer" href="https://shop.shroom4you.com/where-to-buy/">
                  Where to buy?
                </a>
              </span>

              <span className="navbar-item head-item">
                <a target="_blank" rel="noreferrer" href="https://shop.shroom4you.com/faq/">
                  FAQ
                </a>
              </span>

              <span className="navbar-item head-item">
                <a target="_blank" rel="noreferrer" href="https://shop.shroom4you.com/shroomscriptions/">
                  Shroomscriptions
                </a>
              </span>

                <span className="navbar-item head-item">
                  <a target="_blank" rel="noreferrer" href="https://shop.shroom4you.com/contact/" className="btn n-call">
                    Contact
                  </a>
                </span>
              </div>
            </div>
          </div>
        </nav>
        <a id="lang-switch" href='/pl/'>
          <span className="navbar-item head-item" onClick={setLanguage}>
            <img src={Poland} width="22px" height="22px" id='flag-img' alt="language switcher" />
          </span>
        </a>
        </div>
        </div>

      </header>
      <NewsletterForm />

</>
)
}


function setLanguage(){
  //localStorage.setItem("language", 'PL')

  if(localStorage.getItem("language")=='PL'){
    localStorage.setItem("language", 'EN')
  }else if(localStorage.getItem("language")=='EN'){
    localStorage.setItem("language", 'PL')
  }
}



function toogleNav(){


// Get all "navbar-burger" elements
const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

// Add a click event on each of them
$navbarBurgers.forEach( el => {
  el.addEventListener('click', () => {

    // Get the target from the "data-target" attribute
    const target = el.dataset.target;
    const $target = document.getElementById(target);

    // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
    el.classList.toggle('is-active');
    $target.classList.toggle('is-active');

  });
});


}
