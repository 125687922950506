import { React, useEffect } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import Cta from "./cta"
import Revs from "./reviews"
// import CookieConsent from 'react-cookie-consent'
import FootEuImage from '../images/eu-foot-en.svg'
import Logo from "../images/logo-black.svg"
import Moon from "../images/moon.svg"
import News from "../images/newspaper-solid.svg"
import Star from "../images/star-solid.svg"
import List from "../images/list-solid.svg"
import FootBg from '../images/newshroom.svg'
import Arrow from "../images/arrow-alt-circle-up-solid.svg"

import FacebookIcon from "../icons/facebook-icon";
import TwitterIcon from "../icons/twitter-icon";
import InstagramIcon from "../icons/instagram-icon";
import LinkedInIcon from "../icons/linkedin-icon";
// import ConsentForm  from '../components/cookies/ConsentForm'

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";


const Layout = ({ isHomePage, children }) => {

  useEffect(() => {
    window.onscroll = function() {
      scrollFunction()};

      window.onscroll = function() {
        if(window.scrollY >= 45){
        document.querySelector("#gatsby-focus-wrapper > header > div").style.top = '0px';
        document.querySelector("#gatsby-focus-wrapper > header > div").style.position = 'fixed'
      }else{document.querySelector("#gatsby-focus-wrapper > header > div").style.top = '0px'
      document.querySelector("#gatsby-focus-wrapper > header > div").style.position = 'relative'
      }
        };
  },[])

  const staticSocialLink = [
		{ iconName: "instagram",iconUrl: "https://www.instagram.com/shroom4you"},
		{ iconName: "linkedin", iconUrl: "https://www.linkedin.com/company/shroom4you/" },
		{ iconName: "facebook", iconUrl: "https://www.facebook.com/shroom4you" },
		// { iconName: "twitter", iconUrl: "https://twitter.com/" },
		// { iconName: "youtube", iconUrl: "https://youtube.com/" },
	];

	const socialLinksData = staticSocialLink;

  const {
    wp,
    allWpProductCategory,
    allWpCategory,
    allWpProduct,
    allWpPost,
  } = useStaticQuery(graphql`
    query LayoutQuery {
    wp {
      generalSettings {
        description
      }
    }
    allWpProductCategory(filter: {language: {code: {eq: EN}}}) {
      edges {
        node {
          name
          uri
        }
      }
    }
    allWpCategory(filter: {language: {code: {eq: EN}}}) {
      nodes {
        name
        uri
      }
    }
    allWpProduct(filter: {productCategories: {nodes: {elemMatch: {slug: {eq: "mushroom-and-botanical-drinks"}}}}}) {
      edges {
        node {
          name
          link
        }
      }
    }
    allWpPost(sort: {fields: [date], order: DESC}, limit: 4, filter: {language: {code: {eq: EN}}}) {
      edges {
        node {
          dateGmt
          link
          title
        }
      }
    }
  }
  `)

  return (
    <>
    <Header />
    <div data-is-root-path={isHomePage}>
      <main>{children}</main>
      <Cta />
      <footer className="hero section is-black has-text-centered" style={{backgroundColor:'#4C365C',backgroundImage:`url(${FootBg})`,backgroundRepeat:'no-repeat',backgroundPosition:'right bottom'}}>

      <div style={{textAlign:'center'}}>
      <Link style={{textAlign:'left',padding:'25px'}} to="/">
        <img src={Logo} alt="logo" style={{filter:'invert(1)',margin:'0px',paddingBottom:'80px'}} width="100px" />
      </Link>
      </div>
      <br />
      <div className="columns" style={{textAlign:'left'}}>
        <ol className="column">
        <li className="navbar-item subtitle" style={{color:'white'}}><a target="_blank" rel="noreferrer" href="https://shop.shroom4you.com">Shop</a></li>
          <li className="navbar-item"><a target="_blank" rel="noreferrer" href={'https://shop.shroom4you.com'}>Shop</a></li>
            <li className="navbar-item"><a target="_blank" rel="noreferrer" href={'https://shop.shroom4you.com/cart/'}>Cart</a></li>
            <li className="navbar-item"><a target="_blank" rel="noreferrer" href={'https://shop.shroom4you.com/my-account/'}>My account</a></li>
            <li className="navbar-item"><a target="_blank" rel="noreferrer" href={'https://shop.shroom4you.com/wishlist/'}>Wishlist</a></li>
            <li className="navbar-item"><a target="_blank" rel="noreferrer" href={'https://shop.shroom4you.com/shroomscriptions/'}>Shroomscriptions</a></li>
          <li className="navbar-item subtitle" style={{color:'white'}}>
          Categories
          </li>

            {allWpProductCategory.edges.map(post => {
              return (
              <>
              <li className="navbar-item"><a target="_blank" rel="noreferrer" href={'https://shop.shroom4you.com'+post.node.uri}>{post.node.name}</a></li>
              </>)})}
        </ol>
        <ol className="column">
        <li className="navbar-item subtitle" style={{color:'white'}}><a target="_blank" rel="noreferrer" href="https://shop.shroom4you.com/our-story/">About us</a></li>
        <li className="navbar-item"><a target="_blank" rel="noreferrer" href="https://shop.shroom4you.com/where-to-buy/">Where to buy?</a></li>
        <li className="navbar-item"><a target="_blank" rel="noreferrer" href={'https://shop.shroom4you.com/faq/'}>FAQ</a></li>
        <li className="navbar-item"><a target="_blank" rel="noreferrer" href={'https://shop.shroom4you.com/contact/'}>Contact us</a></li>
        <li className="navbar-item subtitle" style={{color:'white'}}>
        <Link to="/blog/">News</Link></li>
          {allWpCategory.nodes.map(post => {
            return (
            <>
            <li className="navbar-item"><Link href={post.uri}>{post.name}</Link></li>
            </>)})}
        </ol>
        <ol className="column">
        <li className="navbar-item subtitle" style={{color:'white'}}>
        <Link href="/blog/">Blog</Link></li>
          {allWpPost.edges.map(post => {
            return (
            <>
            <li className="navbar-item"><Link to={post.node.link}>{post.node.title}</Link></li>
            </>)})}
        <li className="navbar-item subtitle" style={{color:'white'}}><a target="_blank" rel="noreferrer" href="https://shop.shroom4you.com/product-category/mushroom-and-botanical-drinks/">Shroom Drinks</a></li>
          {allWpProduct.edges.map(post => {
            return (
            <>
            <li className="navbar-item"><a target="_blank" rel="noreferrer" href={'https://shop.shroom4you.com'+post.node.link}>{post.node.name}</a></li>
            </>)})}
        </ol>
        <div className="column">
        <ol>
        <li className="navbar-item subtitle" style={{color:'white'}}>
        Shroom Sp. z o.o.</li>
					<li className="navbar-item" key={'KRS'}>KRS: 0000867640</li>
					<li className="navbar-item" key={'NIP'}>VATEU: PL7162830959</li>
					<li className="navbar-item" key={'REGON'}>REGON: 387403324</li>
					<li className="navbar-item" key={'Share capital'}>Share capital: 5000 PLN</li>
        </ol>
        <br />
        <p className="navbar-item subtitle" style={{color:'white'}}>
        Other</p>
        <button onClick={newsEnter} className="btn btn-outline-primary" style={{minWidth:'150px',color:'white'}}>Newsletter&nbsp;&nbsp;
        <span className="icon">
          <i className="contact-icon"><img src={News} alt="icon" width="auto" style={{maxWidth:'12px',minWidth:'10px',filter:'invert(1)'}} /></i>
        </span>
        </button><br /><br />
        <Link to="/psychotest/" className="btn btn-outline-primary" style={{minWidth:'150px'}}>Psychotest&nbsp;&nbsp;
        <span className="icon">
          <i className="contact-icon"><img src={List} alt="icon" width="auto" style={{maxWidth:'12px',minWidth:'10px',filter:'invert(1)'}} /></i>
        </span>
        </Link>

        <div className="hidden" style={{textAlign:'center',padding:'15px'}}>
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.2143 1.5625H1.78013C0.797991 1.5625 0 2.27051 0 3.13965V21.8604C0 22.7295 0.797991 23.4375 1.78013 23.4375H23.2143C24.1964 23.4375 25 22.7295 25 21.8604V3.13965C25 2.27051 24.1964 1.5625 23.2143 1.5625ZM7.5558 20.3125H3.85045V9.87305H7.56138V20.3125H7.5558ZM5.70312 8.44727C4.51451 8.44727 3.55469 7.60254 3.55469 6.56738C3.55469 5.53223 4.51451 4.6875 5.70312 4.6875C6.88616 4.6875 7.85156 5.53223 7.85156 6.56738C7.85156 7.60742 6.89174 8.44727 5.70312 8.44727ZM21.4453 20.3125H17.74V15.2344C17.74 14.0234 17.7121 12.4658 15.8147 12.4658C13.8839 12.4658 13.5882 13.7842 13.5882 15.1465V20.3125H9.88281V9.87305H13.4375V11.2988H13.4877C13.9844 10.4785 15.1953 9.61426 16.9978 9.61426C20.7478 9.61426 21.4453 11.7773 21.4453 14.5898V20.3125Z" fill="white"/>
        </svg>
        &nbsp;&nbsp;&nbsp;
        {/*<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.2143 1.5625H1.78013C0.797991 1.5625 0 2.27051 0 3.13965V21.8604C0 22.7295 0.797991 23.4375 1.78013 23.4375H23.2143C24.1964 23.4375 25 22.7295 25 21.8604V3.13965C25 2.27051 24.1964 1.5625 23.2143 1.5625ZM7.5558 20.3125H3.85045V9.87305H7.56138V20.3125H7.5558ZM5.70312 8.44727C4.51451 8.44727 3.55469 7.60254 3.55469 6.56738C3.55469 5.53223 4.51451 4.6875 5.70312 4.6875C6.88616 4.6875 7.85156 5.53223 7.85156 6.56738C7.85156 7.60742 6.89174 8.44727 5.70312 8.44727ZM21.4453 20.3125H17.74V15.2344C17.74 14.0234 17.7121 12.4658 15.8147 12.4658C13.8839 12.4658 13.5882 13.7842 13.5882 15.1465V20.3125H9.88281V9.87305H13.4375V11.2988H13.4877C13.9844 10.4785 15.1953 9.61426 16.9978 9.61426C20.7478 9.61426 21.4453 11.7773 21.4453 14.5898V20.3125Z" fill="white"/>
        </svg>*/}
        </div>
        </div>

      </div>
      {/*
        Consent mode v2 IMPLEMENTATION OF GDPR COOKIE NOTICE
      <ConsentForm /> */}
      {/*<CookieConsent
          location="bottom"
          buttonText="Accept"
          cookieName="gdpr-cookies"
          style={{ width:'90%',background: "#111111", color:"white",minWidth:'150px',maxWidth:'500px', marginLeft:".5%",marginBottom:".5%",borderRadius:"20px",flex: '0 0 200px' }}
          buttonStyle={{ color: "#FFFFFF",marginTop:'20px', backgroundColor:'#4C365C', fontSize: "1em",marginRight: '5%',marginLeft: '5%',padding:'12px',borderRadius:'10px'}}
          expires={150}
          debug={false}
          >
          <div style={{marginLeft:'2%',marginRight:'2%',marginBottom:'10px',fontSize:'.8em',textAlign:'left'}}>
          <p className="cookie-class"> We use <b>cookies</b> <span role="img" aria-label="cookie-emoji">🍪</span> &nbsp;in order to improve user experience and optimize our website.<br></br> <span style={{ fontSize: "10px", }}>
            Check out our <a className="link-danger" href="/privacy-policy/" rel="noopener noreferrer" target="_blank"> privacy policy</a> to learn more.</span> </p>
          </div>
        </CookieConsent>*/}

<button onClick={topFunction} id="back-to-top" style={{position:'fixed',width:'35px',height:'35px',right:'2px',bottom:'2px',textAlign:'center'}} className="button is-black is-outlined is-small">
<img src={Arrow} alt="back-to-top" width="30px" />
</button>


      <p>
        <a href="/">Shroom © {new Date().getFullYear()}</a>
        <br />
        <a target="_blank" rel="noreferrer" href="https://shop.shroom4you.com/privacy-policy/">Privacy policy</a> |&nbsp;
        <a target="_blank" rel="noreferrer" href="https://shop.shroom4you.com/store-regulations/">Store regulations</a> | &nbsp;
        <a target="_blank" rel="noreferrer" href="https://shop.shroom4you.com/refunds-policy/">Refunds & Complaints policy</a>
      </p>
      { socialLinksData.length ? (
        <div style={{display:'flex',flexWrap:'wrap',justifyContent:'center'}}>
          { socialLinksData.map( ( socialLink ) => (
            <span key={ socialLink.iconName } style={{margin:'10px'}}>
              <a href={ socialLink.iconUrl } aria-label={socialLink.iconName} target="_blank" rel="noreferrer">
                { "instagram" === socialLink.iconName ? <InstagramIcon/>  : null }
                { "linkedin" === socialLink.iconName ? <LinkedInIcon/> : null }
                { "facebook" === socialLink.iconName ? <FacebookIcon/>  : null }
              </a>
            </span>
          ) ) }
        </div>
      ) : null }
      </footer>
    </div>
    <p style={{textAlign:'center',backgroundColor:'white',padding:'20px 0px'}}>
    <img src={FootEuImage} id="foot-logo" alt="logo" width="auto" style={{maxWidth:'400px',width:'75%',minWidth:'200px'}} />
    </p>
    </>
  )
}

export default Layout



const newsEnter = () => {
  let i = 0;
  console.log('enter modal');
  document.getElementById('newsletter').style.display = 'block';
  document.getElementById('newsletter').style.transition = 'opacity 0.5s';

  setTimeout(function(){document.getElementById('newsletter').style.opacity = '1';},500);
}

// When the user scrolls down 20px from the top of the document, show the button


function scrollFunction() {
  // Get the button:
  let mybutton = document.getElementById("back-to-top");
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    mybutton.style.display = "block";
  } else {
    mybutton.style.display = "none";
  }
 
}

// When the user clicks on the button, scroll to the top of the document
function topFunction() {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}
