import React from 'react'
import { navigate } from 'gatsby-link'


function encode(data) {
  const formData = new FormData()


  for (const key of Object.keys(data)) {
    formData.append(key, data[key])
  }

  return formData

}


const newsDestroy = () => {
  console.log('destroy modal');

  document.getElementById('newsletter').style.transition = 'opacity 1s';
  document.getElementById('newsletter').style.opacity = '0';
  setTimeout(function(){document.getElementById('newsletter').style.display = 'none';
  },500);
}


	const newsEnter = () => {
		console.log('enter modal');
		document.getElementById('newsletter').style.display = 'block';
		document.getElementById('newsletter').style.transition = 'opacity 0.5s';

		setTimeout(function(){document.getElementById('newsletter').style.opacity = '1';},500);
	}




class NewsletterForm extends React.Component {

  componentDidMount() {
    // This code will run after the component mounts
    //console.log('Component did mount');
    }

  constructor(props) {
    super(props)
    this.state = {}
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => {
        // Set userSubscribed in localStorage
        localStorage.setItem('userSubscribed', 'true');
        // Close the pop-up
        newsDestroy();
        //go to thank u page
        navigate('/thank-you/')
    })
      .catch(error => alert(error))
    }

  render() {
    return (
      <div id='newsletter'
      style={{display:'none', transition:'opacity 1s',opacity:'0',position:'fixed',top:'0px',left:'0px',height:'100%',width:'100%',backgroundColor:'rgba(255,255,255,0.85)',zIndex:'20000',overflow:'scroll'}}>
        <div style={{marginLeft:'5%',marginRight:'5%',paddingTop:'5%'}}>
      <form
      name="Newsletter"
      id = "newsletter-form"
      method="post"
      action="/thank-you/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={this.handleSubmit}
      style={{marginLeft:'auto',marginRight:'auto',minHeight:'350px',maxWidth:'600px',borderRadius:'25px',padding:'10px',paddingTop:'35px',backgroundImage:`url(https://admin.shroom4you.com/wp-content/uploads/2024/01/news2.jpg)`,backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
      <button className='btn btn-danger' style={{fontSize:'20px',marginTop:'-30px'}} onClick={newsDestroy} >&nbsp;x&nbsp;</button>


      <div>
      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type="hidden" name="form-name" value="newsletter" />
      <div hidden>
        <label>
          Don’t fill this out:{' '}
          <input name="bot-field"
          onChange={this.handleChange}
          />
        </label>
      </div>
      <h3 className='spec-h' style={{color:'whitesmoke',textAlign:'center'}}>Hey, why not sign up for our<br /> newsletter? </h3>
      <br />
      <div style={{textAlign:'center',marginLeft:'30px',marginRight:'30px',backgroundColor:'rgba(15,15,15,.5)',borderRadius:'25px'}}>
      <br />
      <p className='subtitle' style={{color:'whitesmoke'}}> <span style={{backgroundColor:'white',color:'purple'}} className="spec-h">Get special offers and promo codes</span><br />
       Stay up to date with us and our merch!</p>
      <br />
      <div style={{display:'flex',justifyContent:'center'}}>
      <input className='form-control' required={true} onChange={this.handleChange} name='adres email' id="e-mail adress" type='email' placeholder='E-mail adress' style={{margin:'auto',maxWidth:'200px',border:'1px solid #281D47'}}>
      </input>
      </div>
      <br />

      {/*	GDPR Compiliant*/}
      <div className="form-check mt-2">
      <label>
      <input onChange={this.handleChange} required={true} value="yes" className="form-check-input" name="gdpr" type="checkbox"/>
      <span style={{fontSize:'.9em',color:'#fefefe'}}>I consent to the processing of my personal data by Shroom sp. z o.o. for the purpose of direct marketing and sending information regarding special offers and information via electronic communication tools. Information on how the data is processed is available in <a href="https://shop.shroom4you.com/privacy-policy/">Privacy Policy</a>.</span>
      </label>
      </div>

      <br />

      <button className="btn btn-primary" type="submit" >
      Subscribe!
      </button>
      <br />
      <br />
      </div>
      <div style={{textAlign:'center'}}>
      <br />

      <a className='text-dark' href="https://shop.shroom4you.com/privacy-policy/">Privacy policy</a>
      <br />
      <br />
        </div>
      </div>
      </form>
      </div>
    </div>
    )
  }
}



export default NewsletterForm
